import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientDataRequest } from 'src/app/shared/services/requests/orchestrator-telemedicine/patient-data.request';
import { GenerateTelemedicineEpisodeResponse } from 'src/app/shared/services/responses/orchestrator-telemedicine/generate-telemedicine-episode.response';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { EpisodeData, PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';

@Component({
  selector: 'app-orientations',
  templateUrl: './orientations.component.html',
  styleUrls: ['./orientations.component.css']
})
export class OrientationsComponent implements OnInit {

  @Input() colorCode: string;
  @Input() telemedicineConfig: TelemedicineConfigStruct;
  @Input() guid: string;
  @Output() next = new EventEmitter<any>();

  constructor(private alertService: AlertService,
    private utilsTelemedicineService: UtilsTelemedicineService) { }

  public checkedPlace: boolean = false;
  public checkedNotDriving: boolean = false;
  public checkedClothing: boolean = false;
  public isDisabled: boolean = false;
  public shadow: string = "none";
  public btnColor: string = "#E9EBF4";

  public checkedLoading: boolean = false;
  public isLoading: boolean = false;
  public idHealthIdentification: number;
  public patientData: PatientData;
  public patientDataRequest: PatientDataRequest = new PatientDataRequest();
  public generateTelemedicineEpisodeResponse: GenerateTelemedicineEpisodeResponse;
  public idEpisode: number;
  public hasWaitingTime: boolean = false;
  public actualTicket: string = "-";
  public lastTicket: string = "-";
  public waitingTime: string = "-";
  public uri: string;
  public isAllChecked: boolean = false;
  public flowEnum = TelemedicineFlowEnum.orientations;

  ngOnInit(): void {
    this.utilsTelemedicineService.setOldFlowControl(TelemedicineFlowEnum.orientations);
  }

  forward() {
    if (this.checkedPlace && this.checkedNotDriving && this.checkedClothing) {
      this.isDisabled = true;
      this.isLoading = true;
      this.next.emit();
    }
    else
      this.alertService.show('Erro', "Você precisa concordar com todos os itens", AlertType.error);
  }

  onCheckChange() {
    if (this.checkedPlace && this.checkedNotDriving && this.checkedClothing) {
      this.isAllChecked = true;
      this.shadow = "0 0 4px #065f19";
    }
    else {
      this.isAllChecked = false;
      this.shadow = "none";
    }
  }
}