<div class="main-container">
    <app-telemedicine-header [telemedicineConfig]="telemedicineConfig" *ngIf="!isMobileVersion || isFinished"></app-telemedicine-header>
    <div [ngClass]="{'container-background':!isMobileVersion}" [ngStyle]="{'background-color': (colorCode && !isMobileVersion)? colorCode : null}" *ngIf="!isFinished">
        <div [ngClass]="{'body-content-video-call-not-mobile':!isMobileVersion}">
            <div [ngClass]="{'client-body-mobile':isMobileVersion, 'white-container-attend-video':!isMobileVersion}">
                <span *ngIf="isMobileVersion" class="doc-name-mobile" [ngStyle]="{'color': colorCode}">Profissional:
                    {{this.videoCallData?.attendantUserName}}
                </span>
                <app-twilio-video #twiliovideo  (leaveRoom)="onLeaveRoom($event)" [isMobileVersion]="isMobileVersion"></app-twilio-video>
            </div>
        </div>
        
    </div>
    <app-ended-query *ngIf="endMedicalCare && !isForward" [colorCode]="colorCode" [isMobilVersion]="isMobileVersion" (finish)="finish()"></app-ended-query>
</div>